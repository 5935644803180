import React from "react";
import { Link } from "gatsby";
// import { ModalRoutingContext } from 'gatsby-plugin-modal-routing-3'
import Layout from "../components/layout";

const GalleryContent = (props) => (
  <div>
    <h2>Modal Page</h2>

    <Link to="/">Go back to the homepage</Link>
  </div>
);

const ModalExamplePage = (props) => {
  const modal = true;
  return (
    <div>
      {modal ? (
        <div className="bg-white">
          <Link
            //   to={closeTo}
            state={{
              noScroll: true,
            }}
          >
            Close Modal
          </Link>
          <GalleryContent />
        </div>
      ) : (
        <Layout pageProps={props}>
          <GalleryContent />
        </Layout>
      )}
    </div>
  );
};

export default ModalExamplePage;
